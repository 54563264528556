<template>
  <MiscellaneousDeliveryModifier dialog-name="Delivery Modifiers" />
</template>
<script>
import MiscellaneousDeliveryModifier from '@/views/app/miscellaneous/DeliveryModifier'

export default {
  name: 'DeliveryModifierWrapper',
  components: {
    MiscellaneousDeliveryModifier
  }
}
</script>
